<template>
  <u-expansion-panels @update:model-value="handleUpdateModel">
    <u-expansion-panel
      v-for="panel in blok.body"
      :key="panel._uid"
      :title="panel.title"
    >
      <u-content :document="panel.description" />
    </u-expansion-panel>
  </u-expansion-panels>
</template>

<script setup lang="ts">
import type { SbBlokData } from '@storyblok/vue'
import { isUndefined } from 'lodash-es'

const { trackEvent } = useGtm()
const { gtm } = defineProps<{
  blok: SbBlokData
  gtm?: Partial<{
    event: string
    category: string
    action: string
    label: string
  }>
}>()

function handleUpdateModel(value: number) {
  if (!isUndefined(value) && !!gtm) {
    trackEvent({
      event: gtm.event,
      eventCategory: gtm.category,
      eventAction: gtm.action,
      eventLabel: `${gtm.label}_${value + 1}`,
    })
  }
}
</script>
